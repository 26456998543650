import { ProductSearchResult } from '@msdyn365-commerce/retail-proxy';
import { SupersessionKey } from './SupersessionKey';

export default function getProductSupersessionProperty(product: ProductSearchResult, extensionKey: SupersessionKey) {
    const properties = product.ExtensionProperties;
    if (properties) {
        return properties.find(pr => pr.Key === extensionKey)?.Value?.StringValue || null;
    }
    return null;
}
