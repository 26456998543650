import React from 'react';

interface IAddToCartListComponentProps {
  productId: number
  onAddToCart(id: number, quantity: number): void;
  addToCartButtonText: string
}

export const AddToCartListComponent: React.FC<IAddToCartListComponentProps> = ({productId, onAddToCart, addToCartButtonText}) => {

  const [ quantity , setQuantity ] = React.useState<number>(1);

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuantity(e.target.valueAsNumber);
  }
  const handleAddToCart = () => onAddToCart(productId, quantity);

  return (
    <>
      <td>
        <input type="number" min={"1"} max={"10"} value={quantity} aria-label={'Change Quantity'} onChange={handleQuantityChange}/>
      </td>
      <td><button type={'button'} className={'list-view__add-to-cart-btn msc-button'} onClick={handleAddToCart}>{addToCartButtonText}</button></td>
    </>
  )
}